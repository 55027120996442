import React, { useState } from "react"
import { useForm } from "react-hook-form"

import IconArrowRight from "../../images/icon-arrow-right.png"

const Form = ({ promotionName }) => {
  const [formSubmitSuccess, setFormSubmitSuccess] = useState(false)
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm()
  const onSubmit = data => {
    data.promotionName = promotionName
    console.log(data)
    reset()
    // window.dataLayer = window.dataLayer || []
    // window.dataLayer.push({
    //   event: "emailsubmit",
    //   email: data.email,
    //   first_name: data.firstName,
    //   last_name: data.lastName,
    //   Address: data.address,
    //   entryfield1: data.entryField1,
    //   entryfield2: data.entryField2,
    //   favorite_product: data.favouriteProduct,
    //   phone: data.contactNumber,
    //   Promotion_Name: data.promotionName,
    //   status: "success", //capture either error or success. Error would be a wrong email, wrong format, or someone that did not optin to the T&C
    //   "marketing optin": data.marketingConsent, // value can be 1 or 0. 1 if the box is checked 0 if its not.
    // })
    setFormSubmitSuccess(true)
    setTimeout(() => setFormSubmitSuccess(false), 6000)
  }

  return (
    <>
      <div
        className={`alert alert-success ${formSubmitSuccess && "in"}`}
        role="alert"
      >
        Thank You! Your name has been successfully added to our mailing list.
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-2">
          <div className="row g-2">
            <div className="col-md-6">
              <input
                type="text"
                className={`form-control ${errors.firstName && "error"}`}
                placeholder="First Name"
                {...register("firstName", { required: true })}
              />
            </div>
            <div className="col-md-6">
              <input
                type="text"
                className={`form-control ${errors.lastName && "error"}`}
                placeholder="Last Name"
                {...register("lastName", { required: true })}
              />
            </div>
            <div className="col-md-6">
              <input
                type="email"
                className={`form-control ${errors.email && "error"}`}
                placeholder="Email"
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Please enter a valid email",
                  },
                })}
              />
            </div>
            <div className="col-md-6">
              <input
                type="text"
                maxLength="15"
                className={`form-control ${errors.contactNumber && "error"}`}
                placeholder="Tel"
                {...register("contactNumber", {
                  required: "Contact phone number is required",
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Please enter a number",
                  },
                  minLength: {
                    value: 10,
                    message: "The minimum length is 10",
                  },
                })}
              />
            </div>
            <div className="col-12">
              <textarea
                className={`form-control ${errors.address && "error"}`}
                placeholder="Address"
                rows="3"
                {...register("address", { required: true })}
              ></textarea>
            </div>
            <div className="col-md-6">
              <input
                type="text"
                className={`form-control ${errors.entryField1 && "error"}`}
                placeholder="Entry Field 1"
                {...register("entryField1", { required: true })}
              />
            </div>
            <div className="col-md-6">
              <input
                type="text"
                className={`form-control ${errors.entryField2 && "error"}`}
                placeholder="Entry Field 2"
                {...register("entryField2", { required: true })}
              />
            </div>
            <div className="col-12">
              <select
                className={`form-control ${errors.favouriteProduct && "error"}`}
                {...register("favouriteProduct", { required: true })}
              >
                <option selected value="">
                  Favourite product
                </option>
                <option value="Biscuits">Biscuits</option>
                <option value="Cakes">Cakes</option>
                <option value="Milk Drink">Milk Drink</option>
                <option value="Vegan Products">Vegan Products</option>
                <option value="Protein Products">Protein Products</option>
                <option value="Spreads &amp; Sauces">
                  Spreads &amp; Sauces
                </option>
              </select>
            </div>
          </div>
        </div>

        <button type="submit" className="btn mb-3">
          Submit{" "}
          <img className="ms-1" src={IconArrowRight} alt="Icon Arrow Right" />
        </button>
        <div className="mb-3 form-check">
          <input
            type="checkbox"
            className="form-check-input"
            id="marketingConsent"
            {...register("marketingConsent")}
          />
          <label className="form-check-label" htmlFor="marketingConsent">
            Please tick the box if you're happy to be contacted by Treats by
            Mars for marketing purposes
          </label>
        </div>
      </form>
    </>
  )
}

export default Form
