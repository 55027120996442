import React from "react"

const Header = () => {
  return (
    <>
      <div className="main-header text-center position-relative">
        <div className="content">
          <h1>Treats by Mars</h1>
          <p className="marsFont-bold text-sm">
            Did you know there were so many ways to enjoy your favourite
            confectionery brands?
          </p>
        </div>
      </div>
    </>
  )
}

export default Header
