import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const LatestGiveaways = () => {
  return (
    <>
      <div className="latest-giveaway">
        <div className="heading">
          <h2 className="text-center">The latest giveaways</h2>
        </div>

        <div className="row g-4">
          <div className="col-md-6 col-lg-3">
            <div className="latest-giveaway-card">
              <figure>
                <StaticImage
                  className="img-fluid"
                  src="../../images/ice-lollies-starburst-multipack-sq.jpg"
                  alt="Ice lollies Starburst Multipack SQ"
                />
              </figure>
              <h3>COMING SOON – WIN A COOL SUMMER DINING SET</h3>
              {/* <a href="#">Find out more &gt;</a> */}
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <div className="latest-giveaway-card">
              <figure>
                <StaticImage
                  className="img-fluid"
                  src="../../images/galaxy-brownie-mix-lifestyle-sq.jpg"
                  alt="Galaxy Brownie Mix Lifestyle SQ"
                />
              </figure>
              <h3>COMING SOON – WIN A FOOD MIXER WITH GALAXY BAKING MIX</h3>
              {/* <a href="#">Find out more &gt;</a> */}
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <div className="latest-giveaway-card">
              <figure>
                <StaticImage
                  className="img-fluid"
                  src="../../images/mars-dessert-sauces-sharing-board.jpg"
                  alt="Mars Dessert Sauces Sharing Board"
                />
              </figure>
              <h3>SNICKERS… THE NEW SAUCE ON THE BLOCK!</h3>
              {/* <a href="#">Find out more &gt;</a> */}
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <div className="latest-giveaway-card">
              <figure>
                <StaticImage
                  className="img-fluid"
                  src="../../images/milkyway-magic.jpg"
                  alt="MilkyWay magic"
                />
              </figure>
              <h3> MILKYWAY MAGIC</h3>
              {/* <a href="#">Find out more &gt;</a> */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LatestGiveaways
