import React from "react"
import Form from "./form"

const YourInformation = ({ promotionName }) => {
  return (
    <>
      <div className="your-information py-xl-5">
        <div className="row">
          <div className="col-lg-4">
            <div className="content">
              <h2>Your information</h2>
              <p>
                In order to be in a with a chance to win this giveaway simply
                fill in your details below.
              </p>
            </div>
          </div>
          <div className="col-lg-8">
            <Form promotionName={promotionName} />
          </div>
        </div>
      </div>
    </>
  )
}

export default YourInformation
