import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import LogoMaltesers from "../../images/brands/logo-maltesers.svg"

const Brands = () => {
  return (
    <>
      <section className="bg-white py-3 py-md-4 py-lg-5">
        <div className="container">
          <div className="d-flex flex-wrap flex-lg-nowrap justify-content-lg-between align-items-center brands-hldr">
            <figure className="mb-0">
              <StaticImage
                src="../../images/brands/logo-galaxy.png"
                alt="Logo Galaxy"
              />
            </figure>
            <figure className="mb-0">
              <StaticImage
                src="../../images/brands/logo-starburst.svg"
                alt="Logo Starburst"
              />
            </figure>
            <figure className="mb-0">
              <StaticImage
                src="../../images/brands/logo-tracker.png"
                alt="Logo Tracker"
              />
            </figure>
            <figure className="mb-0">
              <StaticImage
                src="../../images/brands/logo-mms.png"
                alt="Logo MMS"
              />
            </figure>
            <figure className="mb-0">
              <StaticImage
                src="../../images/brands/logo-milkyway.png"
                alt="Logo Milky Way"
              />
            </figure>
            <figure className="mb-0 maltesers">
              <img src={LogoMaltesers} alt="Logo Maltesers" />
            </figure>
            <figure className="mb-0">
              <StaticImage
                src="../../images/brands/logo-mars.svg"
                alt="Logo Mars"
              />
            </figure>
            <figure className="mb-0">
              <StaticImage
                src="../../images/brands/logo-snickers.png"
                alt="Logo Snickers"
              />
            </figure>
            <figure className="mb-0">
              <StaticImage
                src="../../images/brands/logo-skittles.png"
                alt="Logo Skittles"
              />
            </figure>
            <figure className="mb-0">
              <StaticImage
                src="../../images/brands/logo-bounty.png"
                alt="Logo Bounty"
              />
            </figure>
          </div>
        </div>
      </section>
    </>
  )
}

export default Brands
