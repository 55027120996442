import React, { useState, useEffect } from "react"
// import { navigate } from "gatsby"
import Cookies from "universal-cookie"

import Layout from "../../components/TreatByMarsComponents/components/layout"
import Brands from "../../components/TreatByMarsComponents/components/brands/brands"
import Header from "../../components/TreatByMarsComponents/components/header/header"
import ValentineGiveaway from "../../components/TreatByMarsComponents/components/valentineGiveaway/valentineGiveaway"
import LatestGiveaways from "../../components/TreatByMarsComponents/components/latestGiveaways/latestGiveaways"
import YourInformation from "../../components/TreatByMarsComponents/components/yourInformation/yourInformation"
import Footer from "../../components/TreatByMarsComponents/components/footer/footer"

import AgegateModal from "../../components/TreatByMarsComponents/components/agegate/agegate-modal"

const IndexPage = () => {
  const [ageCheck, setAgeCheck] = useState(false)

  useEffect(() => {
    let ageCookie = new Cookies()
    if (!ageCookie.get("isOldEnough")) {
      //navigate("/agegate")
      document.querySelector("body").className = "overflow-lock"
      window.scrollTo(0, 0)
      setAgeCheck(true)
      // window.dataLayer = window.dataLayer || []
      // window.dataLayer.push({
      //   event: "virtualPageview",
      //   page: "/agegate",
      // })
    } else {
      setAgeCheck(false)
      // window.dataLayer = window.dataLayer || []
      // window.dataLayer.push({
      //   event: "virtualPageview",
      //   page: "/landingpage",
      // })
    }
  }, [])
  return (
    <>
      <Layout>
        {/* <Seo title="Home | Snickers Hunger Insurance" lang="en" /> */}
        {/* <Seo
          title="Mars Consumer Drinks and Treats Website – Treats by Mars"
          description="Did you know there were so many ways to enjoy your favourite confectionery brands?"
        /> */}
        <Brands />
        {ageCheck && <AgegateModal setAgeCheck={setAgeCheck} />}
        <section className="main-section">
          <div className="container">
            <Header />
            <ValentineGiveaway />
            <LatestGiveaways />
            <YourInformation
              promotionName={"icelandShoppingVoucherMaltesers"}
            />
          </div>
        </section>

        <Footer />
      </Layout>
    </>
  )
}

export default IndexPage
