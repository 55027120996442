import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const ValentineGiveaway = () => {
  return (
    <>
      <div className="valentine-giveaway bg-white">
        <div className="row align-items-center g-0 g-lg-5">
          <div className="col-lg-6">
            <figure className="mb-4 mb-lg-0">
              <StaticImage
                src="../../images/ice-cream-maltesers-raspberry-multipack-sq.jpg"
                alt="Ice cream maltesers raspberry multipack SQ"
              />
            </figure>
          </div>
          <div className="col-lg-6">
            <div className="content">
              {/* <figure className="d-none d-lg-block">
                <StaticImage
                  src="../../images/icon-heart.png"
                  alt="Icon Heart"
                />
              </figure> */}
              <h2>
                WIN A £200 ICELAND SHOPPING VOUCHER WITH MALTESERS RASPBERRY ICE
                CREAM!
              </h2>
              <p>
                Love the combination of fruity ice cream and chocolate? Meet
                your NEW favourite Maltesers Raspberry Ice Cream!{" "}
              </p>
              <p>
                Get ready to delight in a sweet raspberry flavoured ice cream,
                filled with Maltesers pips and coated in milk chocolate. Perfect
                for summer, perfect for anytime!
              </p>
              <p>
                Maltesers Raspberry Ice Cream is new and exclusive to Iceland,
                and to celebrate we are giving one lucky winner the chance to
                win a £200 shopping voucher to spend at Iceland. To enter,
                simply fill out your details below.
              </p>
              <p>
                T&Cs apply:{" "}
                <a
                  href="https://bit.ly/3FYwkvb"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  https://bit.ly/3FYwkvb
                </a>
                . Closing date July 27 2022.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ValentineGiveaway
